// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.secondry-layout__wrapper {
  padding: 2rem 0;
  min-height: 100vh;
  margin: 0 20rem;
}
@media (max-width: 1679px) {
  .secondry-layout__wrapper {
    margin-left: 15rem;
  }
}
@media (max-width: 1439px) {
  .secondry-layout__wrapper {
    margin-left: 28rem;
  }
}
@media (max-width: 1199px) {
  .secondry-layout__wrapper {
    margin: 0 5rem;
    padding: 1.5rem 0;
  }
}
@media (max-width: 767px) {
  .secondry-layout__wrapper {
    margin: 0 1.5rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Layout/SecondaryLayout/SecondaryLayout.scss"],"names":[],"mappings":"AAME;EACE,eAAA;EACA,iBAAA;EACA,eAAA;AAJJ;AAMI;EALF;IAMI,kBAAA;EAHJ;AACF;AAKI;EATF;IAUI,kBAAA;EAFJ;AACF;AAII;EAbF;IAcI,cAAA;IACA,iBAAA;EADJ;AACF;AAGI;EAlBF;IAmBI,gBAAA;EAAJ;AACF","sourcesContent":["@import \"../../../Assets/Theme/_var.scss\";\n\n.secondry-layout {\n  //   &__inner {\n\n  //   }\n  &__wrapper {\n    padding: 2rem 0;\n    min-height: 100vh;\n    margin: 0 20rem;\n\n    @media (max-width: 1679px) {\n      margin-left: 15rem;\n    }\n\n    @media (max-width: 1439px) {\n      margin-left: 28rem;\n    }\n\n    @media (max-width: 1199px) {\n      margin: 0 5rem;\n      padding: 1.5rem 0;\n    }\n\n    @media (max-width: 767px) {\n      margin: 0 1.5rem;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
