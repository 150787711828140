import { Suspense, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PrimaryLayout from "./Components/Layout/PrimaryLayout/PrimaryLayout";
import {
	AuthLayout,
	Dashboard,
	TermCondition,
	PrivacyPolicy,
	ForgotPassword,
	History,
	Login,
	ResetPassword,
} from "./Pages";
import { Loader } from "./Components/UI";
import SecondaryLayout from "./Components/Layout/SecondaryLayout/SecondaryLayout";
import AuthGuard from "./utils/AuthGuard";
import { SOCKET_URL } from "./utils/constants";
import { io } from "socket.io-client";

export const socket = io(SOCKET_URL, {
	reconnection: true,
	transports: ["websocket"],
});	

socket.on("connect", () => {
	console.log("Connnected");
});
function App() {
	const router = createBrowserRouter([
		{
			element: <AuthLayout />,
			children: [
				{
					path: "/",
					element: <Login />,
				},
				{
					path: "forgot-password",
					element: <ForgotPassword />,
				},
				{
					path: "reset-password",
					element: <ResetPassword />,
				},
			],
		},

		{
			element: (
				<AuthGuard>
					<PrimaryLayout />
				</AuthGuard>
			),
			children: [
				{
					path: "/dashboard",
					element: <Dashboard />,
				},

				{
					path: "/history",
					element: <History />,
				},
			],
		},
		{
			element: <SecondaryLayout />,
			children: [
				{
					path: "/termcondition",
					element: <TermCondition />,
				},
				{
					path: "/privacypolicy",
					element: <PrivacyPolicy />,
				},
			],
		},
	]);

	return (
		<Suspense
			fallback={
				<>
					<Loader />
				</>
			}
		>
			<RouterProvider router={router} />
		</Suspense>
	);
}

export default App;
