// import axios from "axios";
import axios, { AxiosInstance } from "axios";
import { SITE_URL } from "../utils/constants";
import store from "../Redux/store";
import { resetUserSlice } from "../Redux/slices/admin.slice";
import { toast } from "../Components/Layout/Toast/Toast";

// Create an axios instance for the second base URL
const axiosInstance: AxiosInstance = axios.create({
	baseURL: SITE_URL,
});

// axios.defaults.baseURL = SITE_URL;
// axios request interceptor~~~~~~~~~~~~

axiosInstance.interceptors.response.use(
	(config) => {
		//   config.headers["api-access-token"] = token;
		return config;
	},
	(error) => {
		if (error?.response?.status == "401") {
			// window.location.replace("/");
			store.dispatch(resetUserSlice());
			toast.info("Session Expired");
		}
		return error;
	}
);

// function to format url as and if params

function formatUrl(url: any, params: any) {
	if (params) {
		params =
			params && Object.keys(params).length > 0
				? `?${new URLSearchParams(params).toString()}`
				: "";
	}
	return `${url}${params}`;
}

// get request function~~~~~~~~

export const apiCallGet = (
	url: string,
	token?: string,
	refreshToken?: string,
	params = {}
) =>
	new Promise((resolve, reject) => {
		const headers = {
			"Content-Type": "application/json",
			"x-auth-token": token,
			"x-refresh-token": refreshToken,
			"Access-Control-Allow-Origin": "*",
		};

		axiosInstance
			.get(formatUrl(url, params), { headers })
			.then((res) => {
				resolve(res.data);
			})
			.catch((error) => {
				reject(error?.response?.data);
			});
	});

// post request function~~~~~~~~

export const apiCallPostHeader = (
	url: any,
	data: any,
	params: any,
	jwt?: any
) =>
	new Promise((resolve, reject) => {
		const headers = {
			"Content-Type": "application/json",
			"x-auth-token": jwt,
			"Access-Control-Allow-Origin": "*",
		};
		axiosInstance
			.post(formatUrl(url, params), Object.keys(data).length ? data : {}, {
				headers: headers,
			})
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error?.response?.data);
			});
	});

export const apiCallPatchHeader = (
	url: any,
	data: any,
	params: any,
	jwt?: any
) =>
	new Promise((resolve, reject) => {
		const headers = {
			"Content-Type": "application/json",
			authorization: jwt,
			"Access-Control-Allow-Origin": "*",
		};

		axiosInstance
			.patch(formatUrl(url, params), Object.keys(data).length ? data : {}, {
				headers: headers,
			})
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error?.data);
			});
	});

export const apiCallPost = (url: any, data: any, params: any) =>
	new Promise((resolve, reject) => {
		axiosInstance
			.post(formatUrl(url, params), Object.keys(data).length ? data : null)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error?.data);
			});
	});
