import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.scss";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import store from "./Redux/store";
import { GoogleOAuthProvider } from "@react-oauth/google";

let persistor = persistStore(store);
const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	// <React.StrictMode>
	<Provider store={store}>
		<PersistGate loading={"Loading..."} persistor={persistor}>
			<GoogleOAuthProvider clientId="1053619308083-uubjsf988okpsh7mo9qqurbicp03cre7.apps.googleusercontent.com">
				<App />
			</GoogleOAuthProvider>
		</PersistGate>
	</Provider>
	// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
