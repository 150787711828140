// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-graph {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 2rem 2.5rem;
  border-radius: 2rem;
  height: 100%;
}
@media (max-width: 767px) {
  .chart-graph {
    border-radius: 1rem;
    padding: 2rem 1.5rem;
  }
}
.chart-graph__graph {
  height: 28.5rem;
}`, "",{"version":3,"sources":["webpack://./src/Components/UI/Chart/Chart.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,oBAAA;EACA,mBAAA;EACA,YAAA;AACF;AACE;EARF;IASI,mBAAA;IACA,oBAAA;EAEF;AACF;AAAE;EACE,eAAA;AAEJ","sourcesContent":["@import \"../../../Assets/Theme/_var.scss\";\n.chart-graph {\n  display: flex;\n  flex-direction: column;\n  background-color: $white;\n  padding: 2rem 2.5rem;\n  border-radius: 2rem;\n  height: 100%;\n\n  @media (max-width: 767px) {\n    border-radius: 1rem;\n    padding: 2rem 1.5rem;\n  }\n\n  &__graph {\n    height: 28.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
