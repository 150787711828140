// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary-layout__inner {
  min-height: 100vh;
  margin-left: 35rem;
}
@media (max-width: 1679px) {
  .primary-layout__inner {
    margin-left: 32rem;
  }
}
@media (max-width: 1439px) {
  .primary-layout__inner {
    margin-left: 28rem;
  }
}
@media (max-width: 1199px) {
  .primary-layout__inner {
    margin-left: 0;
  }
}
.primary-layout__wrapper {
  padding: 2rem 0;
}
@media (max-width: 1199px) {
  .primary-layout__wrapper {
    padding: 1.5rem 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Layout/PrimaryLayout/PrimaryLayout.scss"],"names":[],"mappings":"AAGE;EACE,iBAAA;EACA,kBAAA;AADJ;AAGI;EAJF;IAKI,kBAAA;EAAJ;AACF;AACI;EAPF;IAQI,kBAAA;EAEJ;AACF;AADI;EAVF;IAWI,cAAA;EAIJ;AACF;AAFE;EACE,eAAA;AAIJ;AAFI;EAHF;IAII,iBAAA;EAKJ;AACF","sourcesContent":["@import \"../../../Assets/Theme/_var.scss\";\n\n.primary-layout {\n  &__inner {\n    min-height: 100vh;\n    margin-left: 35rem;\n\n    @media (max-width: 1679px) {\n      margin-left: 32rem;\n    }\n    @media (max-width: 1439px) {\n      margin-left: 28rem;\n    }\n    @media (max-width: 1199px) {\n      margin-left: 0;\n    }\n  }\n  &__wrapper {\n    padding: 2rem 0;\n\n    @media (max-width: 1199px) {\n      padding: 1.5rem 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
