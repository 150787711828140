import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import "./CustomPagination.scss";

const CustomPagination = (props: {
	total?: number;
	setCurrentPage?: any;
	currentPage?: any;
}) => {
	return (
		<Pagination
			className="custom-pagination"
			prevIcon="&lt;"
			nextIcon="&gt;"
			total={props?.total || 0}
			current={props?.currentPage}
			// current={1}
			// hideOnSinglePage={false}
			defaultPageSize={1}
			onChange={(page: number) => props?.setCurrentPage(page)}
		/>
	);
};

export default CustomPagination;
