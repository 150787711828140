import { useNavigate } from "react-router-dom";
import CustomModal from "../CustomModal";
import CustomButton from "../../CustomButton/CustomButton";
import "./LogoutModal.scss";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../../../services/apiServices";
import { toast } from "../../../Layout/Toast/Toast";
import { resetUserSlice } from "../../../../Redux/slices/admin.slice";

const LogoutModal = ({ show, onHide }: { show?: boolean; onHide?: any }) => {
	const token = useSelector((state: any) => state?.admin?.jwt);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleLogout = async () => {
		const result = await logoutUser(token);
		if (result.status == 200) {
			dispatch(resetUserSlice());
			toast.success(result?.message);
			navigate("/");
		}
	};
	return (
		<CustomModal
			show={show}
			onHide={onHide}
			title="Logout"
			className="logout-modal"
		>
			<p>Are you sure you want to logout?</p>
			<div className="logout-modal__btns">
				<CustomButton text="Cancel" className="outline-btn" onClick={onHide} />
				<CustomButton text="Logout" onClick={() => handleLogout()} />
			</div>
		</CustomModal>
	);
};

export default LogoutModal;
