import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Header, Sidebar } from "../../UI";
import "./PrimaryLayout.scss";
import { useDispatch, useSelector } from "react-redux";
import { setExpirationTime } from "../../../Redux/slices/admin.slice";
import { fetchToken } from "../../../services/apiServices";

const PrimaryLayout = ({ className }: { className?: string }) => {
	const dispatch = useDispatch();
	const { expirationTime, refreshToken } = useSelector(
		(state: any) => state?.admin
	);
	const [active, setActive] = useState(false);
	const handleActive = () => {
		window.screen.availWidth < 1200 && setActive(!active);
	};

	// useEffect(() => {
	// 	let interval: NodeJS.Timeout;
	// 	const checkExpiryTime = () => {
	// 		const currentTimestamp = Date.now();
	// 		if (expirationTime) {
	// 			const timeDiff = currentTimestamp - expirationTime;

	// 			if (timeDiff >= 500000) {
	// 				fetchToken(refreshToken);
	// 				const currentTimestamp = new Date().getTime();
	// 				dispatch(setExpirationTime(currentTimestamp));
	// 			}
	// 		}
	// 	};

	// 	interval = setInterval(() => {
	// 		checkExpiryTime();
	// 	}, 60000);

	// 	return () => clearInterval(interval);
	// }, [dispatch]);
	return (
		<main className="primary-layout">
			<Sidebar active={active} handleActive={handleActive} />
			<div className="primary-layout__inner">
				<Header active={active} handleActive={handleActive} />
				<div className="primary-layout__wrapper">
					<Outlet />
				</div>
			</div>
		</main>
	);
};

export default PrimaryLayout;
