// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.graph-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
.graph-filter h5 {
  font-size: 2.2rem;
  font-weight: 700;
}
.graph-filter .common-select .react-select__control {
  background-color: #ffffff;
  min-width: 9.6rem;
  padding: 0.5rem 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/Components/UI/Chart/GraphFilter/GraphFilter.scss","webpack://./src/Assets/Theme/_var.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AAAF;AAEE;EACE,iBAAA;EACA,gBAAA;AAAJ;AAKM;EACE,yBCZA;EDaA,iBAAA;EACA,sBAAA;AAHR","sourcesContent":["@import \"../../../../Assets/Theme/_var.scss\";\n\n.graph-filter {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 2rem;\n\n  h5 {\n    font-size: 2.2rem;\n    font-weight: 700;\n  }\n\n  .common-select {\n    .react-select {\n      &__control {\n        background-color: $white;\n        min-width: 9.6rem;\n        padding: 0.5rem 1.5rem;\n      }\n    }\n  }\n}\n","@import url(\"https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap\");\n\n$baseFont: \"Nunito\", sans-serif;\n\n$white: #ffffff;\n$lightBlue: rgb(241, 241, 255);\n$lightGray: rgb(241, 241, 241);\n$lightGray2: rgb(245, 245, 245);\n$black: #000000;\n$primary: #2A7B97;\n$secondary2: rgba(34, 34, 34, 1);\n$secondary3: #a3a3a3;\n$secondary4: rgb(114, 114, 114);\n$secondary5: rgba(46, 46, 46, 1);\n$borderClr: rgba(228, 228, 228, 1);\n$borderClr2: #252525;\n\n$green: #7bbb3f;\n$blue: #2797d1;\n$red: #c3292d;\n$orange: #f68630;\n$skyblue: #01cde4;\n$yellowdark: #fbbb10;\n$reddark: #e54c40;\n\n$gradient2: linear-gradient(\n  180deg,\n  #fdfdfd 33.45%,\n  rgba(255, 255, 255, 0.79) 119.09%\n);\n\n$boxShadow: 0 1.2rem 1.6rem -0.4rem rgba(255, 255, 255, 0.2),\n  0 0.4rem 0.6rem -0.2rem rgba(255, 255, 255, 0.15);\n$boxShadow2: 0 1rem 1.4rem rgba(255, 255, 255, 0.06);\n$boxShadow3: inset 3px 17px 37px rgba(3, 3, 3, 0.6),\n  inset 0px 0px 5px rgba(255, 255, 255, 0.1);\n\n// z indexes\n$header: 988;\n$sidebar: $header + 3;\n$sidebar_overlay: $sidebar - 2;\n$modal: 1056;\n$loader: 1090;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
