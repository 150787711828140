import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Header } from "../../UI";
import './SecondaryLayout.scss'

const SecondaryLayout = () => {
  const [active, setActive] = useState(false);
  const handleActive = () => {
    window.screen.availWidth < 1200 && setActive(!active);
  };
  return (
    <main className="secondry-layout">
      <div className="secondry-layout__inner">
        <Header active={active} handleActive={handleActive} />
        <div className="secondry-layout__wrapper">
          <Outlet />
        </div>
      </div>
    </main>
  );
};

export default SecondaryLayout;
