// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  padding: 1.2rem 3rem;
  background: #ffffff;
  position: sticky;
  z-index: 11;
  top: 0;
  min-height: 9rem;
  display: flex;
  align-items: center;
}
@media (max-width: 1439px) {
  .header {
    min-height: 7.64rem;
  }
}
@media (max-width: 1199px) {
  .header {
    padding: 1rem 1.5rem;
  }
}
.header__inner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__inner h5 {
  font-weight: 700;
}
@media (max-width: 767px) {
  .header__inner h5 {
    display: none;
  }
}
.header__inner__actions {
  display: flex;
  align-items: center;
  gap: 2rem;
}
@media (max-width: 1199px) {
  .header__inner__actions {
    gap: 1.5rem;
  }
}
@media (max-width: 767px) {
  .header__inner__actions {
    gap: 1rem;
  }
}
@media (max-width: 767px) {
  .header__inner__actions .user-info {
    display: none;
  }
}
@media (max-width: 1199px) {
  .header__inner .header-logo {
    max-width: 6rem;
  }
}
@media (max-width: 767px) {
  .header__inner .header-logo {
    max-width: 4rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/UI/Header/Header.scss"],"names":[],"mappings":"AAEA;EACE,oBAAA;EACA,mBAAA;EACA,gBAAA;EACA,WAAA;EACA,MAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;AAAF;AAEE;EAVF;IAWI,mBAAA;EACF;AACF;AACE;EAdF;IAeI,oBAAA;EAEF;AACF;AAAE;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAEJ;AAAI;EACE,gBAAA;AAEN;AAAM;EAHF;IAII,aAAA;EAGN;AACF;AAAI;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAEN;AAAM;EALF;IAMI,WAAA;EAGN;AACF;AAFM;EARF;IASI,SAAA;EAKN;AACF;AAFQ;EADF;IAEI,aAAA;EAKR;AACF;AAAM;EADF;IAEI,eAAA;EAGN;AACF;AAFM;EAJF;IAKI,eAAA;EAKN;AACF","sourcesContent":["@import \"../../../Assets/Theme/_var.scss\";\n\n.header {\n  padding: 1.2rem 3rem;\n  background: $white;\n  position: sticky;\n  z-index: 11;\n  top: 0;\n  min-height: 9rem;\n  display: flex;\n  align-items: center;\n\n  @media (max-width: 1439px) {\n    min-height: 7.64rem;\n  }\n\n  @media (max-width: 1199px) {\n    padding: 1rem 1.5rem;\n  }\n\n  &__inner {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n\n    h5 {\n      font-weight: 700;\n\n      @media (max-width: 767px) {\n        display: none;\n      }\n    }\n\n    &__actions {\n      display: flex;\n      align-items: center;\n      gap: 2rem;\n\n      @media (max-width: 1199px) {\n        gap: 1.5rem;\n      }\n      @media (max-width: 767px) {\n        gap: 1rem;\n      }\n\n      .user-info {\n        @media (max-width: 767px) {\n          display: none;\n        }\n      }\n    }\n\n    .header-logo {\n      @media (max-width: 1199px) {\n        max-width: 6rem;\n      }\n      @media (max-width: 767px) {\n        max-width: 4rem;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
