import { createSlice } from "@reduxjs/toolkit";

export interface adminState {
	email: string;
	jwt: string;
	expirationTime: number;
	refreshToken: string;
}

const initialState: adminState = {
	email: "",
	jwt: "",
	expirationTime: 0,
	refreshToken: "",
};

const adminReducer = createSlice({
	name: "admin",
	initialState,
	reducers: {
		setEmail: (state, action) => {
			state.email = action.payload;
		},
		setJwt: (state, action) => {
			state.jwt = action.payload;
		},
		setExpirationTime: (state, action) => {
			state.expirationTime = action.payload;
		},
		setRefreshToken: (state, action) => {
			state.refreshToken = action.payload;
		},
		resetUserSlice: () => initialState,
	},
});

export default adminReducer.reducer;
export const {
	setEmail,
	setJwt,
	setExpirationTime,
	setRefreshToken,
	resetUserSlice,
} = adminReducer.actions;
