export const SITE_URL: any = "https://api.kingofcryptocoin.co.uk/v1/admin";
export const SOCKET_URL: any = "https://api.kingofcryptocoin.co.uk";
export const EXPLORER_URL: any = "https://etherscan.io/tx/";

export const APIURL = {
	LOGIN_USER: "/login",
	FORGOT_PWD: "/password/forget",
	VERIFY_OTP_CODE: "/verify-otp",
	RESEND_OTP_CODE: "/otpResend",
	RESET_PWD: "/password",
	DASHBOARD: "/dashboard",
	TRNX_HISTORY: "/logs",
	REFRESH_TOKEN: "/refresh-token",
	LOGOUT_USER: "/logOut",
	GRAPH: "/graph-data",
	TWO_FA: "/2fa",

};