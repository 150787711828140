import { CustomSelect } from "../../Formik/FormikFields";
import "./GraphFilter.scss";

const GraphFilter = ({ setDate }: { setDate: any }) => {
	const options = [
		{ value: "30", label: "30 Days" },
		{ value: "15", label: "15 Days" },
		{ value: "7", label: "7 Days" },
		{ value: "1", label: "1 Day" },
	];
	return (
		<div className="graph-filter">
			<h5>Graph</h5>
			<CustomSelect
				options={options}
				defaultValue={options[0]}
				isSearchable={false}
				setDate={setDate}
			/>
		</div>
	);
};

export default GraphFilter;
