// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ct-toast-success h4.ct-heading {
  color: #000 !important;
}

.ct-toast-error h4.ct-heading {
  color: #000 !important;
}

.ct-toast-info h4.ct-heading {
  color: #000 !important;
}

.ct-toast svg {
  width: 20px;
  height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Layout/Toast/Toast.scss"],"names":[],"mappings":"AACI;EACI,sBAAA;AAAR;;AAKI;EACI,sBAAA;AAFR;;AAOI;EACI,sBAAA;AAJR;;AASI;EACI,WAAA;EACA,YAAA;AANR","sourcesContent":[".ct-toast-success {\n    h4.ct-heading {\n        color: #000 !important;\n    }\n}\n\n.ct-toast-error {\n    h4.ct-heading {\n        color: #000 !important;\n    }\n}\n\n.ct-toast-info {\n    h4.ct-heading {\n        color: #000 !important;\n    }\n}\n\n.ct-toast {\n    svg {\n        width: 20px;\n        height: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
